import Moment from "moment";

export default {
  name: "HistoryLogUserSign",
  data() {
    return {
      property: {
        listElement: {
          historyLogUserSign: {
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          userCode: "",
          officeCode: "",
          userName: "",
          date: "",
        },
      },
      options: {
        office: [],
      },
      table: {
        data: {
          historyLogUserSign: [],
        },
      },
    };
  },
  methods: {
    async onKillSession(props) {
      if (!this.connectionNavigator()) return;
      const confirmation = await this.simpleConfirmation(
        "Hapus Sesi ?",
        "is-danger"
      );
      if (!confirmation) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "user-management",
          reqUrl: "user-signin-log/kill-session/" + props.row.userId,
        });
        await this.simpleWait(1000);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          this.resetHistoryLogSignTable();
          this.setDefaultDate();
          this.getDataTableHistoryLogUserSign();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1000);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async resetHistoryLogSignTable() {
      this.table.data.historyLogUserSign = [];
      this.property.filterDto.userCode = "";
      this.property.filterDto.officeCode = "";
      this.property.filterDto.userName = "";
      this.property.filterDto.date = "";
    },
    async onChangePage(type) {
      let date = "";
      if (type === "n") {
        date = Moment(this.property.filterDto.date).add(1, "days");
      }
      if (type === "p") {
        date = Moment(this.property.filterDto.date).subtract(1, "days");
      }
      this.property.filterDto.date = Moment(date).format("YYYY-MM-DD");
      this.findByHistoryLogUserSign();
    },
    Moment: Moment,
    async findByHistoryLogUserSign() {
      this.table.data.historyLogUserSign = [];
      this.property.listElement.historyLogUserSign.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          reqUrl: "user-signin-log/simple-list",
          payload: {
            userCode: this.property.filterDto.userCode,
            officeCode: this.property.filterDto.officeCode,
            userName: this.property.filterDto.userName,
            date: this.property.filterDto.date,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.historyLogUserSign = resp.data.data;
          } else {
            this.failedGetDataHistoryLogUserSign(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorHistoryLogUserSign(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.historyLogUserSign.downloading = false;
        }, timeout + 500);
      }
    },
    handleCatchErrorHistoryLogUserSign(error) {
      console.log(error.response);
      this.table.data.historyLogUserSign = [];
      this.property.listElement.historyLogUserSign.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getDataTableHistoryLogUserSign() {
      this.table.data.historyLogUserSign = [];
      this.property.listElement.historyLogUserSign.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          reqUrl: "user-signin-log/simple-list",
          payload: {
            userCode: this.property.filterDto.userCode,
            officeCode: this.property.filterDto.officeCode,
            userName: this.property.filterDto.userName,
            date: this.property.filterDto.date,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.historyLogUserSign = resp.data.data;
          } else {
            this.failedGetDataHistoryLogUserSign(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorHistoryLogUserSign(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.historyLogUserSign.downloading = false;
        }, timeout);
      }
    },
    failedGetDataHistoryLogUserSign(resp) {
      this.table.data.historyLogUserSign = [];
      this.property.listElement.historyLogUserSign.message = resp.data.message;
    },
    async getRefenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = index.officeName;
            this.options.office.push({ text, value });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    setDefaultDate() {
      this.property.filterDto.date = Moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.setDefaultDate();
    this.getDataTableHistoryLogUserSign();
    Moment.locale("ID");
    this.getRefenceOffice();
  },
};
